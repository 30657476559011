import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"me.jpeg"} className="App-logo" alt="logo" />
        <p>
          Diniz Sá portofolio website coming soon
        </p>
        <p>Web3 Fullstack | Smart contracts | Blockchain | C++ | Game development</p>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/diniz-s%C3%A1-1a3a62b8/"
          rel="noopener noreferrer"
        >
          Linkedin profile
        </a>
      </header>
    </div>
  );
}

export default App;
